import React from "react";
import classNames from "classnames";
import { CopySection, NextArrow } from "@molecules";
import { Container, Image } from "@atoms";
import useLang from "@hooks/useLang";
import t from "@utils/t";

const Challenge = ({
  heading,
  copy,
  next,
  twoColumns,
  lessBottomSpacing,
  image0,
  image1,
}) => {
  const lang = useLang();

  return (
    <Container>
      <CopySection
        heading={heading}
        copy={copy}
        twoColumns={twoColumns}
        className="text-white"
      />
      {(image0 || image1) && (
        <div className="mt-24 flex w-full flex-col gap-10 md:flex-row">
          <Image
            image={image0}
            caption={`${t("Photo courtesy of", lang)} ${image0.attribution}`}
          />
          <Image
            image={image1}
            caption={`${t("Photo courtesy of", lang)} ${image1.attribution}`}
          />
        </div>
      )}
      <div
        className={classNames("mt-10 flex flex-col sm:flex-row", {
          "min-h-[15vh] sm:min-h-[33vh]": !lessBottomSpacing,
          "min-h-[4rem] sm:min-h-[6rem]": lessBottomSpacing,
        })}
      >
        {next && <NextArrow next={next} />}
      </div>
    </Container>
  );
};

export default Challenge;
